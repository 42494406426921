<template>
  <div class="ly_introduce" ref="ly_introduce">
    <div
      class="ly_introduce-nav"
      ref="ly_introduceNav"
      @mousewheel="mouseWheelHandle"
      @DOMMouseScroll="mouseWheelHandle"
      v-if="jsList.length != 0"
    >
      <div
        v-for="(item, idx) in jsList"
        :key="idx"
        class="nav-nr"
        :style="{ height: isHeight + 'px' }"
      >
        <div
          class="nav-nr-bg"
          :style="{ backgroundImage: `url(${item.cover_image})` }"
        >
          <div class="box" :class="'box-' + idx">
            <img :src="item.site_images" alt="" />
            <div
              class="box-info"
              :class="[fullpage.current == idx + 1 ? 'xz_info' : '']"
            >
              {{ item.title }}
            </div>
            <p
              v-html="item.maincontent"
              :class="[fullpage.current == idx + 1 ? 'xz_p' : '']"
            ></p>
          </div>
        </div>
        <div
          class="nav-nr-sj"
          :style="{ backgroundImage: `url(${item.h5qrcode_image})` }"
        >
          <div class="box" :class="'box-' + idx">
            <img :src="item.site_images" alt="" />
            <div
              class="box-info"
              :class="[fullpage.current == idx + 1 ? 'xz_info' : '']"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ly_schedule">
      <ul>
        <li
          v-for="(item, idx) in jsList"
          :key="idx"
          :style="{
            backgroundColor:
              fullpage.current >= idx + 1 ? '#fff' : 'rgba(255, 255, 255, 0.3)'
          }"
          @click="onFanhuiTop(idx + 1)"
        ></li>
        <div class="ul_nav">
          {{ '0' + fullpage.current }}
          <span>—</span>
          {{ '0' + jsList.length }}
        </div>
      </ul>
    </div>
    <Footer
      :currentData="currentData"
      :style="{ opacity: show ? 1 : 0 }"
    ></Footer>
    <Quickly :currentData="currentData" @onFanhuiTop="onFanhuiTop(1)"></Quickly>
  </div>
</template>
<script>
import Footer from '@/common/footer/index-footer.vue'
import Quickly from '@/common/quickly/quickly.vue'
import { getBasicMsg, getAllList } from '@/api/api'
export default {
  components: {
    Quickly,
    Footer
  },
  data () {
    return {
      isHeight: 0,
      inAnimation: true,
      fullpage: {
        current: 1, // 当前页面
        isScrolling: false, // 防止滚动多页
        deltaY: 0 // 判断往下往上
      },
      jsList: [],
      currentData: {},
      show: false
    }
  },
  watch:{
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.onFanhuiTop(this.$route.query.currentIdx - 0)
        } 
      },
      deep: true
    }
  },
  methods: {
    getData () {
      this.isHeight = window.innerHeight
      let that = this
      getBasicMsg(that.changeData()).then(res => {
        if (res.data.code == 1) {
          that.currentData = res.data.data
          let project = res.data.data.project_id
          let obj = {
            page: 1,
            pagesize: 9,
            type_id: '',
            project: project
          }
          getAllList(obj).then(res => {
            if (res.data.code == 1) {
              that.jsList = res.data.data.list
            }
          })
        }
      })
    },
    onFanhuiTop (i) {
      this.move(i)
      this.fullpage.current = i
    },
    next () {
      // 往下
      let len = this.jsList.length
      if (this.fullpage.current + 1 <= len) {
        this.fullpage.current += 1
        this.move(this.fullpage.current)
      } else {
        this.fullpage.current = len + 1
        this.show = true
      }
    },
    pre () {
      // 往上
      if (this.fullpage.current - 1 > 0) {
        this.fullpage.current -= 1
        this.move(this.fullpage.current)
        this.show = false
      }
    },
    move (index) {
      this.fullpage.current = index
      this.fullpage.isScrolling = true // 防止滚动多页
      this.directToMove(index)
      setTimeout(() => {
        // 延迟1s
        this.fullpage.isScrolling = false
        this.inAnimation = false
      }, 1010)
    },
    directToMove (index) {
      let height = this.$refs['ly_introduce'].clientHeight //获取屏幕的宽度
      let scrollPage = this.$refs['ly_introduceNav'] // 获取执行tarnsform的元素
      let scrollHeight
      scrollHeight = -(index - 1) * height + 'px'
      scrollPage.style.transform = `translateY(${scrollHeight})`
      this.inAnimation = true
    },
    mouseWheelHandle (event) {
      // 监听鼠标监听
      // 添加冒泡阻止
      let evt = event || window.event
      if (evt.stopPropagation) {
        evt.stopPropagation()
      } else {
        evt.returnValue = false
      }
      if (this.fullpage.isScrolling) {
        // 判断是否可以滚动
        return false
      }
      let e = event.originalEvent || event
      this.fullpage.deltaY = e.deltaY || e.detail // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next()
      } else if (this.fullpage.deltaY < 0) {
        this.pre()
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style scoped lang="less">
.ly_introduce {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .ly_introduce-nav {
    width: 100%;
    height: 100vh;
    transition: all linear 0.5s;
    position: relative;
    .nav-nr {
      height: 100%;
      transition: all linear 0.5s;
      .nav-nr-bg,
      .nav-nr-sj {
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        .box {
          position: absolute;
          text-align: center;
          width: 35vw;
          z-index: 3;
          display: flex;
          align-items: center;
          font-size: 1vw;
          .box-info {
            position: absolute;
            display: flex;
            align-items: center;
            top: 1vw;
            left: 5vw;
            width: 5vw;
            height: 5vw;
            text-align: center;
            justify-content: center;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.9);
            box-shadow: 0 0 0.3vw rgba(0, 0, 0, 0.2);
            color: #fd8d00;
            transform: rotateY(180deg);
            opacity: 0;
            transition: 0.8s ease 0.6s;
          }
          .xz_info {
            opacity: 1;
            transform: rotateY(0);
          }
          p {
            text-align: justify;
            line-height: 1.5;
            margin-left: 2vw;
            transition: 1.5s ease 0.6s;
            opacity: 0;
          }
          .xz_p {
            opacity: 1;
          }
        }
        .box-0 {
          left: 43%;
          top: 30%;
        }
        .box-1 {
          left: 33.25%;
          top: 50.06%;
        }
        .box-2 {
          left: 37.55%;
          top: 64.26%;
        }
        .box-3 {
          left: 13.45%;
          top: 27.26%;
        }
        .box-4 {
          left: 47.55%;
          top: 58.65%;
        }
        .box-5 {
          left: 15.55%;
          top: 59.26%;
        }
        .box-6 {
          left: 38.95%;
          top: 48.26%;
        }
      }
      .nav-nr-sj {
        display: none;
      }
    }
  }
  .ly_schedule {
    position: fixed;
    left: 5%;
    top: 25%;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      li {
        width: 2px;
        height: 60px;
        transition: all linear 1s;
        cursor: pointer;
      }
      .ul_nav {
        width: 20px;
        color: #fff;
        margin-top: 1vw;
        text-align: center;
      }
    }
  }
  .index-footer {
    position: absolute;
    bottom: 0;
    transition: all linear 0.5s;
  }
}
</style>
